import { ACCION_EJEC, TINCLUSION } from '@/utils/consts'

export default {
  async insert (Vue, formData, idparteTrabajo, idchecklistOt, idtecnico) {
    const tables = Vue.$offline.db.tables
    const materiales = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.parte_trabajo.idparte_trabajo.eq(tables.parte_trabajo_matsist.idparte_trabajo)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.accion_ejec,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.accion_ejec.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.accion_ejec.idest_accion_ejec.eq(ACCION_EJEC.estados.pendiente),
          tables.accion_ejec.idmaccion.in(formData.idmaccion),
          tables.subsis.idsubsis.in(formData.idsubsis),
          tables.parte_trabajo_matsist.estado.gt(0),
          tables.orden_trabajo_matsist.estado.gt(0),
          tables.material_sistema.estado.gt(0),
          tables.material_sistema.fdesinstalacion.isNull(),
          tables.subsis.estado.gt(0),
          tables.accion_ejec.estado.gt(0),
        )
      )
      .exec()
    for (const material of materiales) {
      await Vue.$offline.accionEjec.marcarAccionEjecRealizadaSync(
        material.accion_ejec.idaccion_ejec,
        idchecklistOt,
        material.accion_ejec.facturable,
        null,
        TINCLUSION.suministro,
        idtecnico,
        idparteTrabajo,
      )
    }
  },
}
